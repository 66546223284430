import { t } from '@zupr/i18n'
import React from 'react'
import { PriceText } from './price'

interface DiscountProps {
    originalPrice: number
    price: number
    currency?: 'EUR'
}

const Discount = ({ originalPrice, price, currency }: DiscountProps) => {
    const discount = originalPrice - price
    const percentage = (discount / originalPrice) * 100

    // small percentage modulo 1%/2.5%
    if (percentage % 1 === 0 || percentage % 2.5 === 0) {
        return (
            <React.Fragment>
                {`${percentage}% ${t('korting', 'dutch')}`}
            </React.Fragment>
        )
    }

    // absulute modulo €5
    if ((discount / 100) % 1 === 0) {
        return (
            <React.Fragment>
                <PriceText short currency={currency} price={discount} />{' '}
                {t('korting', 'dutch')}
            </React.Fragment>
        )
    }

    return t('Sale price')
}

export default Discount
