import categoriesQuery from '@zupr/queries/categories'
import { Category, DomainConfig } from '@zupr/types/fo'
import {
    QueryCategoriesArgs,
} from '@zupr/types/graphql'
import { Req } from '@zupr/types/next'

import doQuery from './graphql'

const fetchPages = async (
    variables: QueryCategoriesArgs,
    req: Req
) => {
    const result = await doQuery<
        QueryCategoriesArgs
    >({
        query: categoriesQuery,
        variables,
    }, req)
    return result.data.categories
}

export const getCategories = async (
    shoppingAreaSlug: DomainConfig['slug'],
    req: Req
): Promise<Category[]> => {
    let page = 20
    let categories = []

    // fetch the first x pages
    const pages = await Promise.all(
        [...Array(page)].map((_, page) =>
            fetchPages({
                shoppingAreaSlug: shoppingAreaSlug || 'nederland',
                offset: page * 100,
            }, req)
        )
    )

    pages.map((page) => {
        const data = page.edges.map(({ node }) => node)
        categories = [...categories, ...data]
    })

    const [lastPage] = pages.slice(-1)

    while (true) {
        if (!lastPage.pageInfo.hasNextPage) break

        // if last page has no more nextPage break immediataly
        const result = await fetchPages({
            shoppingAreaSlug: shoppingAreaSlug || 'nederland',
            offset: page * 100,
        }, req)

        const data = result.edges.map(({ node }) => node)
        categories = [...categories, ...data]

        page = page + 1

        if (!(result && result.pageInfo && result.pageInfo.hasNextPage)) break
    }

    return categories
}

interface GetFullpathForSlug {
    slug: Category['slug']
    categories: Category[]
}

export const getFullpathForSlug = ({
    slug,
    categories,
}: GetFullpathForSlug): Category[] => {
    // find category for the slug
    const leaf = categories.find((category) => {
        return category.slug === slug
    })

    if (!leaf) return null

    // add all children based on path
    return leaf.path.split('.').map((path) =>
        categories.find((category) => {
            return category.slug === path
        })
    )
}

interface GetFilteredCategories {
    slug: DomainConfig['slug']
    variables: {
        depth: number
    }
}

export const getFilteredCategories = async ({
    slug,
    variables,
}: GetFilteredCategories, req: Req): Promise<Category[]> => {
    let categories = await getCategories(slug, req)

    // filter out category based on depth
    if (variables.depth) {
        categories = categories.filter((category) => {
            return category.depth <= variables.depth
        })
    }

    return categories
}
