import OpenAI from 'openai'

interface Props {
    category: string
    subCategories: string[]
    brands: string[]
    parentCategories?: string
    locationTitle?: string
    locationCity?: string
    locationClassification?: string
}

export const generateDescription = async ({
    category,
    subCategories,
    brands,
    parentCategories,
    locationTitle,
    locationCity,
    locationClassification,
}: Props): Promise<string | null> => {
    let prompt = `Creëer een introductietekst van maximaal 75 woorden voor de productcategorie "${category}" `

    if (parentCategories)
        prompt += `in de groep "${parentCategories}" (benoem de groep niet)`

    prompt += ` van ons assortiment. Schrijf een paar zinnen waarin:
    - je de productcategorie aantrekkelijk introduceert.`

    if (subCategories.length > 1) {
        prompt += `- ${
            subCategories.length
        } subcategorieën (${subCategories.join(
            ', '
        )}) binnen ${category} noemt (vermijd hierbij bijvoeglijke naamwoorden).`
    }

    if (brands.length > 1) {
        prompt += `- het filtergemak op ${
            brands.length
        } bekende merken (${brands.join(', ')}) benadrukt.
        `
    }

    if (!locationTitle) {
        prompt += `- het belang van lokaal winkelen verweeft. `
    } else {
        prompt += `- het belang van lokaal winkelen in ${locationCity} bij ${locationTitle} (hint het is een ${locationClassification}) verweeft.`
    }

    prompt += `
    - Vermijd hierbij verwijzingen naar de grootte of actualiteit van het aanbod.
    - Vermijd het gebruik van de woorden: categorie, subcategorie, productcategorie, webshop`

    const openai = new OpenAI({
        apiKey: process.env.OPENAI_API_KEY,
    })

    try {
        const completion = await openai.chat.completions.create({
            messages: [
                {
                    role: 'user',
                    content: prompt,
                },
            ],
            model: 'gpt-3.5-turbo',
        })

        return completion.choices[0].message.content
    } catch (error) {
        console.error(error)
        return null
    }
}
