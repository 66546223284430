import classnames from 'classnames'

import Trans from '../components/trans'

import '../../../scss/react/elements/price.scss'

interface PriceTextProps {
    currency: 'EUR'
    price: number
    lang?: 'nl'
    short: boolean
}

export const PriceText = ({ currency, price, lang, short }: PriceTextProps) => (
    <>
        {(price / 100).toLocaleString(lang || 'nl', {
            style: 'currency',
            currency: currency || 'EUR',
            minimumFractionDigits: short ? 0 : 2,
        })}
    </>
)

PriceText.defaultProps = {
    currency: 'EUR',
    short: false,
}

interface PriceProps {
    isStarting?: boolean
    price: number
    currency?: 'EUR'
    lang?: 'nl'
    striked?: boolean
    discounted?: boolean
    className?: string
}

const Price = ({
    isStarting,
    price,
    currency,
    lang,
    striked,
    discounted,
    className,
}: PriceProps) => (
    <span
        className={classnames('price', className, {
            'price-striked': striked,
            'price-discounted': discounted,
        })}
    >
        {Number.isInteger(price) && isStarting && (
            <span className="starting">
                <Trans label="starting" context="price" />{' '}
            </span>
        )}
        {Number.isInteger(price) && (
            <PriceText price={price} currency={currency} lang={lang} />
        )}
    </span>
)

Price.defaultProps = {
    currency: 'EUR',
}

export default Price
