import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'
import { Brand, Product as SharedProduct } from '@zupr/types/shared'

type Product = SharedProduct | ProductLocation['product']

export const formatSubtitle = (product: Product, brand?: Brand) => {
    if (brand?.title) {
        return brand.title
    }

    if (typeof product?.brand === 'object' && product?.brand?.title) {
        return product.brand.title
    }

    if ('authors' in product && product?.authors?.length) {
        return product.authors.join(', ')
    }

    if (
        product.product_type === 'books' &&
        product.product_additional_data?.book
    ) {
        const book = product.product_additional_data.book
        if (book.authors) return book.authors.join(', ')
        if (book.publisher) return book.publisher
    }

    if (product.product_type) {
        return t(product.product_type, 'types')
    }

    return ''
}

export const formatVariationValues = (values) => {
    values = values.filter((value) => !!value) // remove epmty values
    if (!values.length) return null
    return values.join(', ')
}

export const formatVariationFields = (fields) => {
    return fields && formatVariationValues(Object.values(fields))
}
